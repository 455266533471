import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import API from "../utils/api";
import encodeParams from "../utils/queryParam";

const initialState = {
  isLoading: false,
  data: {
    currentPage: 0,
    hasNext: false,
    hasPrevious: false,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    items: []
  },
  detail: {},
  dataProduct: {
    currentPage: 0,
    hasNext: false,
    hasPrevious: false,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    items: []
  }
};

const slice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    getList(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    setPending(state, action) {
      state.isLoading = true;
    },
    getById(state, action) {
      state.isLoading = false;
      state.detail = action.payload;
    },
    clearById(state, action) {
      state.isLoading = false;
      state.detail = {};
    },
    getListProduct(state, action) {
      state.isLoading = false;
      state.dataProduct = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getCarpark = async (ticketId, licenseNo) => {
  try {
    const data = {
      ticketId,
      licenseNo,
      deviceKey: "TCP-K00001"
    };

    console.log(data, " <---- data");
    const url = `${API.GET_PARKING_FEE}`;
    const req = await axios.post(url, data);
    const res = await req.data;
    return res;
  } catch (err) {
    console.log(JSON.stringify(err));
    return { isError: true };
  }
};

export const getParkingFee = async (body) => {
  try {
    const data = {
      ...body,
      deviceKey: "TCP-K00001"
    };

    console.log(data, " <---- data");
    const url = `${API.GET_PARKING_FEE}`;
    const req = await axios.post(url, data);
    const res = await req.data;
    return res;
  } catch (err) {
    console.log(JSON.stringify(err));
    return { isError: true };
  }
};

export const getCarpark2 = async (data) => {
  try {
      const url = `${API.GET_PARKING_FEE}`;
      const req = await axios.post(url, data);
      const res = await req.data;
      return res;
  } catch (err) {
      console.log(JSON.stringify(err))
      return { isError: true };
  }
};


export const registerDevice = async (deviceKey, deviceBinding) => {
  try {
    const data = {
      deviceKey,
      deviceBinding
    };
    const url = `${API.REGISTER_DEVICE}`;
    const req = await axios.post(url, data);
    const res = await req.data;
    return res;
  } catch (err) {
    console.log(JSON.stringify(err));
    return { isError: true };
  }
};

export const payParkingFee = async (data) => {
  try {
    const url = `${API.PAY_PARKING_FEE}`;
    const req = await axios.post(url, data);
    const res = await req.data;
    return res;
  } catch (err) {
    console.log(JSON.stringify(err));
    return { isError: true };
  }
};

export const setEStamp = async (data) => {
  try {
    const url = `${API.E_STAMP}`;
    const req = await axios.post(url, data);
    const res = await req.data;
    return res;
  } catch (err) {
    console.log(JSON.stringify(err));
    return { isError: true };
  }
};

export const testConnection = async () => {
  try {
    const url = `${API.TEST_CONNECTION}`;
    const req = await axios.get(url);
    const res = await req.data;
    return res;
  } catch (err) {
    console.log(JSON.stringify(err));
    return { isError: true };
  }
};

export const genQrCodePromtpay = async (data) => {
  try {
    const url = `${API.GEN_QR_PROMTPAY}`;
    const req = await axios.post(url, data);
    const res = await req.data;
    return res;
  } catch (err) {
    console.log(JSON.stringify(err));
    return { isError: true };
  }
};

export const genQrCode = async (data) => {
  try {
    const url = `${API.GEN_QR_CODE}`;
    const req = await axios.post(url, data);
    const res = await req.data;
    return res;
  } catch (err) {
    console.log(JSON.stringify(err));
    return { isError: true };
  }
};

export const getReciept = async (invNo) => {
  try {
    const url = `${API.GET_RECIEPT}/${invNo}`;
    const req = await axios.get(url);
    const res = await req.data;
    return res;
  } catch (err) {
    console.log(JSON.stringify(err));
    return { isError: true };
  }
};

export default slice;
