// const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_AUTH_URL = process.env.REACT_APP_AUTHEN_BASE_URL;
const BASE_URL = 'https://api.promjodd.prom.co.th/'

const API = {
  GET_PARKING_FEE: `${BASE_URL}v1/CarPark/GetParkingFee`,
  PAY_PARKING_FEE: `${BASE_URL}v1/CarPark/PayParkingFee`,
  E_STAMP: `${BASE_URL}v1/EStamp`,
  TEST_CONNECTION: `${BASE_URL}v1/TestConnection/TestConnection`,
  REGISTER_DEVICE: `${BASE_URL}v1/Device/RegisterDevice`,
  REMOVE_DEVICE: `${BASE_URL}v1/Device/RemoveDevice`,

  GEN_QR_PROMTPAY: `${BASE_URL}/api/Payment/v1/SCB/QRTag30`,
  GEN_QR_CODE: `${BASE_URL}v1/Payment/QRGenerate`,
  GET_RECIEPT: `${BASE_URL}v1/Reciept/Reciept`,
}

export default API;