import shadows from './shadows';
import typography from './typography';
import palette from './palette';
import overrides from './overrides';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette,
  shadows,
  typography,
  overrides
});

export default theme;
