import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';
// import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  Divider,
  SvgIcon
} from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import { ChevronDown as ChevronDownIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
    background: theme.palette.primary.main
  },
  popover: {
    width: 200
  }
}));

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { user, logout } = useAuth();
//   const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  console.log(user);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(false);
  }, [history.location])

  const handleLogout = async () => {
    try {
      handleClose();


      const idToken = localStorage.getItem('id_token');
      const redirectUri = 'https://tms-web.siamtheatre.com/'
    //   const redirectUri = 'http://localhost:3000/'

      localStorage.removeItem('access_token')
      localStorage.removeItem('id_token')

    //   window.location = `https://localhost:5001/connect/endsession?id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURI(redirectUri)}`
      window.location = `https://sso.siamtheatre.com/connect/endsession?id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURI(redirectUri)}`

      await logout();
      window.location = '/login';
    } catch (err) {
      console.error(err);
    //   enqueueSnackbar('Unable to logout', {
    //     variant: 'error'
    //   });
    }
  };

  const handleChangePassword = async () => {
    try {
      window.location = `https://sso.siamtheatre.com/account/forgotpassword`
    } catch (err) {
      console.error(err);
    //   enqueueSnackbar('Unable to logout', {
    //     variant: 'error'
    //   });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={user?.avatar}
        />
        <Hidden smDown>
          <Typography
            variant="body2"
            color="textPrimary"
          >
            {user?.username || 'Payment Merchant'}
          </Typography>
          <SvgIcon fontSize="small" style={{ marginLeft: 10, color: '#000' }}>
            <ChevronDownIcon />
          </SvgIcon>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
        style={{ padding: 0 }}
      >
        {/* <MenuItem onClick={handleChangePassword}>
          เปลี่ยนรหัสผ่าน
        </MenuItem>
        <Divider /> */}
        <MenuItem onClick={handleLogout}>
          ออกจากระบบ
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;
