/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import NavItem from './NavItem';
import Logo from '../../../components/Logo';

const sections = [
  {
    subheader: 'เมนู',
    items: [
      {
        title: 'หน้าหลัก',
        href: '/',
        img: '/static/images/menus/menu0',
      },
      {
        title: 'ร้านค้า',
        href: '/merchant/users',
        img: '/static/images/menus/menu2',
      },
      {
        title: 'ประวัติการรับชำระเงิน',
        href: '/payment/transaction',
        img: '/static/images/menus/menu1',
      },
      {
        title: 'Biller ID. / Merchant ID.',
        href: '/merchant/biller',
        img: '/static/images/menus/menu5',
      },
      {
        title: 'ผู้ใช้งาน',
        href: '/master/user',
        img: '/static/images/menus/menu10',
      },
    ]
  },
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        img={item.img}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        img={item.img}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    background: theme.palette.background.default,
    // borderRight: '1px solid #D4DDE3',
    color: theme.palette.primary.dark,
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 55,
    height: 'calc(100% - 55px)',
  },
  logo: {
    background: theme.palette.background.default,
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className={classes.root}
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box className={classes.logo} mt={1}>
          <Logo isFull />
        </Box>
        {/* <Box mt={0.5} mb={1.5} justifyContent='center' display='flex'>
          <Typography variant='h5' color='textPrimary'>ผู้ดูแลระบบ</Typography>
        </Box> */}
        <Box p={0} style={{ paddingLeft: 10, paddingRight: 10 }}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  <Typography style={{ color: '#687178', fontSize: 14, padding: '10px 10px' }}>{section.subheader}</Typography>
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
