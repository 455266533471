import React from 'react';
import {
  makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  image: {
    height: 50,
    width: 50,
    // [theme.breakpoints.down('sm')]: {
    //   height: 40,
    //   width: 40,
    // }
    cursor: 'pointer'
  },
  image2: {
    height: 70,
    width: 70,
    cursor: 'pointer'
  },
}));

const Logo = (props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <img
      alt="Logo"
      src="/static/images/PromptRub Logo@2x.png"
      {...props}
      className={props.isFull ? classes.image2: classes.image}
      onClick={() => history.push('/')}
    />
  );
}

export default Logo;
