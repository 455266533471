export default {
    root: {},
    input: {
        height: '1.5rem',
        '&.Mui-disabled': {
            background: '#F2F2F2',
            color: '#687178'
        }
    },
  };
  