import MuiOutlinedInput from './MuiOutlinedInput';
import MuiCardHeader from './MuiCardHeader';
import MuiInputLabel from './MuiInputLabel';
import MuiInputBase from './MuiInputBase';
import MuiButton from './MuiButton';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTableRow from './MuiTableRow';
import MuiSelect from './MuiSelect';
import MuiTypography from './MuiTypography';
import MuiDialog from './MuiDialog';
import MuiDivider from './MuiDivider';
import MuiSwitch from './MuiSwitch';

export default {
  MuiOutlinedInput,
  MuiCardHeader,
  MuiInputLabel,
  MuiInputBase,
  MuiButton,
  MuiTableCell,
  MuiTableHead,
  MuiTableRow,
  MuiSelect,
  MuiTypography,
  MuiDialog,
  MuiDivider,
  MuiSwitch
};
