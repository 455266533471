import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes, { renderRoutes } from './routes';
import { AuthProvider } from './contexts/AuthContext';
import './style.css';
import './assets/css/prism.css';
import './assets/css/index.css';

const history = createBrowserHistory();

const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <GlobalStyles />
        <AuthProvider>
          {renderRoutes(routes)}
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
